<template>
  <div class="analysis-list" v-loading.fullscreen.lock="loading" :element-loading-text="$t('common.preparingData')">
    <ai-breadcrumb />
    <div class="new-button">
      <guide class="guide-button" />
      <el-button class="guide-button" size="small" icon="el-icon-guide" type="info" v-if="analysisType.name === 'TPS'"
        @click="handleReportManual">{{ $t("common.reportManual") }}</el-button>
      <router-link :to="'/services/analysis/' + analysisType.key + '/generate'">
        <el-button icon="el-icon-plus" size="small" type="primary">{{
    $t("analysis.generateReport")
  }}</el-button>
      </router-link>
    </div>
    <div class="search-content">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item class="search-form-gutter" :label="$t('analysis.platform')">
          <el-select style="width: 88px" v-model="currentPlatform" @change="changePlatform" filterable>
            <el-option v-for="item in platformList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="analysisType.name === 'Best10' || analysisType.name === 'Matrix'
    " class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 128px" v-model="date" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="analysisType.name === 'Best10' || analysisType.name === 'Matrix'
    " class="search-form-gutter" :label="$t('analysis.compareDate')">
          <el-select style="width: 128px" v-model="compareDate" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="analysisType.name === 'TPS'" class="search-form-gutter" :label="$t('analysis.group') + '1'">
          <el-select style="width: 118px" v-model="previousGroupFristDates" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 118px" v-model="previousGroupSecondDates" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="analysisType.name === 'TPS'" class="search-form-gutter" :label="$t('analysis.group') + '2'">
          <el-select style="width: 118px" v-model="currentGroupFristDates" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 118px" v-model="currentGroupSecondDates" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" circle @click="fetchData"></el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-loading="tpsPreviewLoading" class="tps-previews" v-if="analysisType.name === 'TPS'">
      <el-row :gutter="10">
        <el-col :span="8" v-for="t in primaryTpsList" :key="t.item">
          <el-card shadow="never">
            <div slot="header" class="">
              <span>{{ t.item }}</span>
            </div>
            <div>
              <el-table cell-class-name="primary-cell" :show-header="false" :data="t.details" row-key="specificItems">
                <el-table-column prop="specificItems"> </el-table-column>
                <el-table-column width="70" class-name="primary-image-cell">
                  <template slot-scope="scope">
                    <router-link target="_blank" :to="{
    path: '/services/analysis/style-search',
    query: { q: scope.row.key, platform: currentPlatform },
  }">
                      <el-image class="primary-image-content" :src="scope.row.url" fit="contain" />
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column min-width="55">
                  <template slot-scope="scope">
                    {{ scope.row.fristAvg.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column min-width="55">
                  <template slot-scope="scope">
                    {{ scope.row.secondAvg.toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column min-width="28">
                  <template slot-scope="scope">
                    <i v-if="scope.row.trend > 0" class="trend-col el-icon-top-right" />
                    <i v-else-if="scope.row.trend < 0" class="trend-col el-icon-bottom-right" />
                    <i v-else class="trend-col el-icon-right" />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="primary-no-data" v-if="!tpsPreviewLoading && (!primaryTpsList || primaryTpsList.length === 0)
    ">
        <span>No Preview Data</span>
      </div>
    </div>
    <div v-loading="matrixPreviewLoading" class="matrix-previews" v-else-if="analysisType.name === 'Matrix'">
      <el-row :gutter="10">
        <el-col :span="8" v-for="t in primaryMatrixList" :key="t.item">
          <el-card shadow="never">
            <div slot="header" class="">
              <span>{{ t.item }}</span>
            </div>
            <div>
              <el-row class="row-head">
                <el-col :span="2" class="q2">
                  <div class="grid-head"></div>
                </el-col>
                <el-col :span="10" class="q2">
                  <div class="grid-head">Small Trend</div>
                </el-col>
                <el-col :span="10" class="q1">
                  <div class="grid-head">Mega Trend</div>
                </el-col>
                <el-col :span="2" class="q1">
                  <div class="grid-head"></div>
                </el-col>
              </el-row>
              <el-row class="row-content">
                <el-col :span="2" class="q2">
                  <div class="grid-content content-title">2Q</div>
                </el-col>
                <el-col :span="10" class="content-border-bottom content-border-right">
                  <div class="grid-content content-container">
                    <el-row class="content-row" v-for="(r, index) in t.q2" :key="index">{{ r }}</el-row>
                  </div>
                </el-col>
                <el-col :span="10" class="content-border-bottom">
                  <div class="grid-content content-container">
                    <el-row class="content-row" v-for="(r, index) in t.q1" :key="index">{{ r }}</el-row>
                  </div>
                </el-col>
                <el-col :span="2" class="q1">
                  <div class="grid-content content-title">1Q</div>
                </el-col>
              </el-row>
              <el-row class="row-content">
                <el-col :span="2" class="q4">
                  <div class="grid-content content-title">4Q</div>
                </el-col>
                <el-col :span="10" class="content-border-right">
                  <div class="grid-content content-container">
                    <el-row class="content-row" v-for="(r, index) in t.q4" :key="index">{{ r }}</el-row>
                  </div>
                </el-col>
                <el-col :span="10" class="">
                  <div class="grid-content content-container">
                    <el-row class="content-row" v-for="(r, index) in t.q3" :key="index">{{ r }}</el-row>
                  </div>
                </el-col>
                <el-col :span="2" class="q3">
                  <div class="grid-content content-title">3Q</div>
                </el-col>
              </el-row>
              <el-row class="row-head">
                <el-col :span="2" class="q4">
                  <div class="grid-head"></div>
                </el-col>
                <el-col :span="10" class="q4">
                  <div class="grid-head">Small Reduction</div>
                </el-col>
                <el-col :span="10" class="q3">
                  <div class="grid-head">Mega Reduction</div>
                </el-col>
                <el-col :span="2" class="q3">
                  <div class="grid-head"></div>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="primary-no-data" v-if="!matrixPreviewLoading &&
    (!primaryMatrixList || primaryMatrixList.length === 0)
    ">
        <span>No Preview Data</span>
      </div>
    </div>
    <div v-loading="best10ImagesLoading" class="best10-images" v-if="analysisType.name === 'Best10'">
      <div v-if="!best10ImagesLoading">
        <el-row :gutter="4" v-for="(recently, idx) in recentlyList" :key="idx">
          <el-col class="best10-images-brand" :lg="3">
            <div class="best10-images-brand-content">
              <el-tag type="info" size="small">{{ $t("common.brand") }}</el-tag>
              <el-link :underline="false" type="info">{{
    recently ? recently.brand : ""
  }}</el-link>
            </div>
          </el-col>
          <el-col :lg="3"><br /></el-col>
          <el-col :lg="2" v-show="currentBrandImagesIdx[idx] === 0" v-for="(item, itemIdx) in recently && recently.urls
    ? recently.urls.slice(0, 10)
    : []" :key="itemIdx">
            <transition name="fade">
              <router-link target="_blank" :to="{
    path: '/services/analysis/style-search',
    query: { q: item.key, platform: currentPlatform },
  }">
                <el-image :src="item.url" width="10px" fit="contain" />
              </router-link>
            </transition>
          </el-col>
          <el-col :lg="2" v-show="currentBrandImagesIdx[idx] === 1" v-for="(item, itemIdx) in recently && recently.urls
    ? recently.urls.slice(10, 20)
    : []" :key="idx + '-' + itemIdx">
            <transition name="fade">
              <router-link target="_blank" :to="{
    path: '/services/analysis/style-search',
    query: { q: item.key, platform: currentPlatform },
  }">
                <el-image :src="item.url" width="10px" fit="contain" />
              </router-link>
            </transition>
          </el-col>
          <el-col class="best10-images-ctl" :lg="1">
            <svg-icon v-if="recently.urls &&
    recently.urls.length > 10 &&
    currentBrandImagesIdx[idx] === 0
    " @click="() => clickNext(idx)" class="next-pre-btn" icon-class="next-btn" />
            <svg-icon v-if="recently.urls &&
    recently.urls.length > 10 &&
    currentBrandImagesIdx[idx] === 1
    " @click="() => clickPre(idx)" class="next-pre-btn" icon-class="pre-btn" />
          </el-col>
        </el-row>
      </div>
      <div v-else class="primary-no-data">
        <span>No Preview Data</span>
      </div>
    </div>
    <el-table :data="tableData" row-key="id" style="width: 100%">
      <el-table-column v-if="analysisType.name === 'Best10' || analysisType.name === 'Matrix'" prop="linkDate"
        :label="$t('analysis.date')"></el-table-column>
      <el-table-column v-if="analysisType.name === 'Best10' || analysisType.name === 'Matrix'" prop="compareLinkDate"
        :label="$t('analysis.compareDate')"></el-table-column>
      <el-table-column v-if="analysisType.name === 'TPS'" prop="previousGroupFristDates" key="previousGroupFristDates"
        :label="$t('analysis.group') + '1-1'"></el-table-column>
      <el-table-column v-if="analysisType.name === 'TPS'" prop="previousGroupSecondDates" key="previousGroupSecondDates"
        :label="$t('analysis.group') + '1-2'"></el-table-column>
      <el-table-column v-if="analysisType.name === 'TPS'" prop="currentGroupFristDates" key="currentGroupFristDates"
        :label="$t('analysis.group') + '2-1'"></el-table-column>
      <el-table-column v-if="analysisType.name === 'TPS'" prop="currentGroupSecondDates" key="currentGroupSecondDates"
        :label="$t('analysis.group') + '2-2'"></el-table-column>
      <el-table-column :label="$t('common.createAt')" min-width="130">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
    scope.row.createdAt | displayTime()
  }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="analysisType.name === 'TPS'" :label="$t('common.summaryReport')" min-width="80">
        <template slot-scope="scope">
          <a :href="scope.row.summaryUrl | ossUrl">
            <el-button :disabled="scope.row.summaryUrl === ''" class="excel-dl-btn" icon="el-icon-download"
              size="small">{{ $t("common.download") }}</el-button>
          </a>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.report')" min-width="80">
        <template slot-scope="scope">
          <a :href="scope.row.url | ossUrl">
            <el-button class="excel-dl-btn" icon="el-icon-download" size="small">{{ $t("common.download") }}</el-button>
          </a>
        </template>
      </el-table-column>
      <el-table-column v-if="analysisType.name === 'Best10'" :label="'Etc Item ' + $t('common.report')" min-width="80">
        <template slot-scope="scope">
          <a v-if="scope.row.etcItemUrl" :href="scope.row.etcItemUrl | ossUrl">
            <el-button class="excel-dl-btn" icon="el-icon-download" size="small">{{ $t("common.download") }}</el-button>
          </a>
          <el-button v-else class="export-btn" type="warning" icon="el-icon-files" size="small"
            @click="exportEtcData(scope.row)">{{ $t("common.export") }}</el-button>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.action')" min-width="80">
        <template slot-scope="scope">
          <el-popconfirm :title="$t('analysis.deleteReport')" @confirm="deleteReport(scope.row.id)">
            <el-button type="danger" icon="el-icon-delete" size="small" slot="reference">{{ $t("common.delete")
              }}</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
  </div>
</template>

<script>
import {
  fetchBest10Reports,
  fetchBest10Recently,
  fetchTPSReports,
  fetchMatrixReports,
  exportBest10Reports,
  deleteReport,
  fetchRecords,
  fetchPrimaryTps,
  fetchPrimaryMatrix,
} from "@/api/analysis";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";
import Guide from "@/components/Guide";
import { downloadFile } from "@/utils/download-file";
import { ossRoot } from "@/utils/server";

export default {
  components: { AiBreadcrumb, Pagination, Guide },
  data() {
    return {
      currentPlatform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      date: null,
      compareDate: null,
      previousGroupFristDates: null,
      previousGroupSecondDates: null,
      currentGroupFristDates: null,
      currentGroupSecondDates: null,
      options: [],
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      recently: {
        brand: null,
        urls: [],
      },
      recentlyList: [],
      total: 0,
      timer: null,
      loading: false,
      primaryTpsList: [],
      primaryMatrixList: [],
      currentBrandImagesIdx: [0, 0, 0],
      tpsPreviewLoading: false,
      best10ImagesLoading: false,
      matrixPreviewLoading: false,
    };
  },
  created() {
    this.fetchData();
    this.fetchOptions();
    if (this.analysisType.name === "Best10") {
      this.fetchBest10Recently();
    } else if (this.analysisType.name === "TPS") {
      this.fetchPrimaryTps();
    } else if (this.analysisType.name === "Matrix") {
      this.fetchPrimaryMatrix();
    }
  },
  methods: {
    fetchOptions() {
      this.date = null;
      this.compareDate = null;
      this.previousGroupFristDates = null;
      this.previousGroupSecondDates = null;
      this.currentGroupFristDates = null;
      this.currentGroupSecondDates = null;
      this.options = [];
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.currentPlatform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.options.push({
                value: e.date,
                label: e.linkDate,
              });
            });
        }
      });
    },
    fetchData() {
      if (this.analysisType.name === "Best10") {
        let params = {
          period: "week",
          date: this.date,
          compareDate: this.compareDate,
          platform: this.currentPlatform,
          ...this.listQuery,
        };
        fetchBest10Reports(params).then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        });
      } else if (this.analysisType.name === "Matrix") {
        let params = {
          date: this.date,
          compareDate: this.compareDate,
          platform: this.currentPlatform,
          ...this.listQuery,
        };
        fetchMatrixReports(params).then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        });
      } else {
        let params = {
          previousGroupFristDates: this.previousGroupFristDates,
          previousGroupSecondDates: this.previousGroupSecondDates,
          currentGroupFristDates: this.currentGroupFristDates,
          currentGroupSecondDates: this.currentGroupSecondDates,
          platform: this.currentPlatform,
          ...this.listQuery,
        };
        fetchTPSReports(params).then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        });
      }
    },
    fetchBest10Recently() {
      this.best10ImagesLoading = true;
      fetchBest10Recently({ platform: this.currentPlatform })
        .then((response) => {
          if (response.success) {
            this.recentlyList = response.result;
          }
        })
        .finally(() => {
          this.best10ImagesLoading = false;
        });
    },
    exportEtcData(row) {
      let doExport = () => {
        this.loading = true;
        // let cb = (response) => {

        // };
        exportBest10Reports({
          period: "week",
          date: row.date,
          compareDate: row.compareDate,
          isEtcItem: true,
        }).then((response) => {
          if (response.success) {
            this.timer = setTimeout(() => {
              this.fetchUrl(row.date, row.compareDate);
            }, 4000);
          }
        });
      };
      if (row.etcItemUrl) {
        this.$confirm(
          this.$t("analysis.reportRegenerate"),
          this.$t("common.pleaseConfirm"),
          {
            type: "warning",
          }
        ).then(() => {
          doExport();
        });
      } else {
        doExport();
      }
    },
    fetchUrl(date, compareDate) {
      this.checkExist(date, compareDate, (url) => {
        if (url) {
          clearInterval(this.timer);
          this.loading = false;
          this.fetchData();
        } else {
          this.timer = setTimeout(() => {
            this.fetchUrl(date, compareDate);
          }, 2000);
        }
      });
    },
    checkExist(date, compareDate, cb) {
      let params = {
        period: "week",
        date: date,
        compareDate: compareDate,
        limit: 1,
      };
      fetchBest10Reports(params).then((response) => {
        let url =
          response.result.items && response.result.items.length > 0
            ? response.result.items[0].etcItemUrl
            : "";
        if (cb) {
          cb(url);
        }
      });
    },
    deleteReport(id) {
      deleteReport(this.analysisType.name, id).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("common.deleteSucc"),
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    handleReportManual() {
      let env = process.env.VUE_APP_ENV;
      if (!env) {
        env = "staging";
      }
      if (env === "production.kr") { env = "production" }
      downloadFile(ossRoot + "/" + env + "/common/TREND MAP_20200723_ALL.pdf");
    },
    fetchPrimaryTps() {
      this.tpsPreviewLoading = true;
      fetchPrimaryTps({ platform: this.currentPlatform })
        .then((response) => {
          if (response.success) {
            this.primaryTpsList = response.result.items || [];
          }
        })
        .finally(() => {
          this.tpsPreviewLoading = false;
        });
    },
    fetchPrimaryMatrix() {
      this.matrixPreviewLoading = true;
      fetchPrimaryMatrix({ platform: this.currentPlatform })
        .then((response) => {
          if (response.success) {
            this.primaryMatrixList = response.result.items || [];
          }
        })
        .finally(() => {
          this.matrixPreviewLoading = false;
        });
    },
    clickNext(idx) {
      this.$set(this.currentBrandImagesIdx, idx, 1);
    },
    clickPre(idx) {
      this.$set(this.currentBrandImagesIdx, idx, 0);
    },
    changePlatform() {
      this.fetchData();
      this.fetchOptions();
      if (this.analysisType.name === "Best10") {
        this.fetchBest10Recently();
      } else if (this.analysisType.name === "TPS") {
        this.fetchPrimaryTps();
      } else if (this.analysisType.name === "Matrix") {
        this.fetchPrimaryMatrix();
      }
    },
  },
  computed: {
    analysisType() {
      if (this.$route.meta && this.$route.meta.key === "tps") {
        return { name: "TPS", key: "tps" };
      } else if (
        this.$route.meta &&
        this.$route.meta.key === "matrix"
      ) {
        return { name: "Matrix", key: "matrix" };
      }
      return { name: "Best10", key: "best10" };
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-list {
  .el-col-lg-2-4 {
    width: 1/11 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;

    .guide-button {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 30px;
      }
    }

    .date-vs {
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .best10-images {
    min-height: 100px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    height: 100%;
    overflow: hidden;
    position: relative;

    .best10-images-brand {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .best10-images-brand-content {
        .el-tag {
          margin-right: 10px;
        }

        .el-link {
          font-size: x-large;
        }
      }
    }

    .best10-images-ctl {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;

      .next-pre-btn {
        width: 100%;
        height: 70px;
      }
    }
  }

  .tps-previews {
    margin-bottom: 10px;
    min-height: 100px;

    .el-card {
      border: none;
      border-radius: 0;
    }

    /deep/ .el-card__header {
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: bold;
    }

    /deep/ .el-card__body {
      padding: 0;
    }

    /deep/ .el-table__row td {
      border: none !important;
      padding: 0;
      font-size: 12px;
    }

    /deep/ .primary-cell .cell {
      max-height: 50px;
    }

    /deep/ .primary-image-cell .cell {
      height: 50px !important;
    }

    .primary-image-content {
      height: 50px;
    }

    .trend-col {
      margin-left: -10px;
      font-size: medium;
    }
  }

  .matrix-previews {
    min-height: 100px;
    margin-bottom: 10px;
    text-align: center;

    .el-card {
      border: none;
      border-radius: 0;
    }

    /deep/ .el-card__header {
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: bold;
    }

    /deep/ .el-card__body {
      padding: 0;
      font-size: 12px;
    }

    .grid-head {
      line-height: 25px;
      min-height: 25px;
      font-weight: bold;
    }

    .grid-content {
      min-height: 125px;
    }

    .content-title {
      line-height: 125px;
      font-weight: bold;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .content-row {
      text-align: center;
      width: 100%;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
    }

    .content-border-bottom {
      box-sizing: border-box;
      border-bottom: 1px solid #606266;
      height: 125px;
      min-height: 125px;
    }

    .content-border-right {
      box-sizing: border-box;
      border-right: 1px solid #606266;
    }

    .q2 {
      color: white;
      background-color: #0b1245;
    }

    .q1 {
      color: white;
      background-color: #670205;
    }

    .q4 {
      background-color: #b7cee3;
    }

    .q3 {
      background-color: #e0c2be;
    }
  }

  .primary-no-data {
    padding-top: 30px;
    text-align: center;

    span {
      color: #909399;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }
}
</style>